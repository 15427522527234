<template>
  <div>
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-row>
        <b-col
          md="12"
          class="mb-3 text-right"
        >
          <b-button
            class=""
            variant="outline-primarySpore"
            @click="showFilter"
          >
            <feather-icon
              icon="FilterIcon"
            />
            <span class="align-middle" v-if="!show"> Mostrar filtros </span>
            <span class="align-middle" v-else > Ocultar filtros </span>
          </b-button>
        </b-col>
      </b-row>
      <b-card no-body v-if="show">
        <b-card-header class="pb-50">
          <h5>
            Filtrar
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              md="2"
              class="mb-md-0 mb-2"
            >
              <b-form-group
                label="Número"
              >
                <b-form-input
                id="number"
                v-model="filters.number"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <b-form-group
                label="."
                style="color:#FFFFFF"
              >
                <b-button
                  variant="primarySpore"
                  @click="search"
                >
                  <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                  />
                  <span>Buscar</span>
                </b-button>
                <b-button
                  class="ml-1"
                  variant="danger"
                  @click="clear"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Limpiar</span>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col md="6" class="mb-3 text-left">
              <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <label>{{ $t('Labels.Show') }}</label>
                <v-select v-model="filters.limit" :options="this.perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @input="getResponse"/>
                <label>{{ $t('Labels.Show') }}</label>
              </b-col>
            </b-col>
            <b-col md="6" class="mb-3 text-right" v-if="userRole !== 'S' && userRole !== 'I'">
              <b-button class="" variant="primarySpore" v-b-tooltip.hover.top title="SiNaMed" @click="add">
                <feather-icon icon="BriefcaseIcon"/>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <h4>Total de medidores: {{ totalRows }}</h4>
            </b-col>
          </b-row>
        </div>
        <div class="text-center" v-if="this.response.length === 0">
          No hay registros que mostrar
        </div>
        <b-table
          v-if="response.length > 0"
          class="position-relative"
          hover
          responsive
          primary-key="id"
          show-empty
          empty-text="No matching records found"
          :items="response"
          :fields="tableColumns"
        >
          <template #cell(number)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.number">
              <b-avatar
                v-if="data.item.number"
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
              >
                <b-img :src="require(`@/assets/images/spore-icons/hash.svg`)" height="20"/>
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ data.item.number.replace(/^(0)+/, '') }}</span>
            </div>
          </template>

          <template #cell(electricCurrent)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.electricCurrent">
              <b-avatar
                v-if="data.item.electricCurrent"
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
              >
                <b-img :src="require(`@/assets/images/spore-icons/energy.svg`)" height="20"/>
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ decimal(data.item.electricCurrent, 3) }} A</span>
            </div>
          </template>
          <template #cell(frecuency)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.frecuency">
              <b-avatar
                v-if="data.item.frecuency"
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
              >
                <b-img :src="require(`@/assets/images/spore-icons/energy.svg`)" height="20"/>
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ decimal(data.item.frecuency, 2) }} Hz</span>
            </div>
          </template>
          <template #cell(stored)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.stored">
              <b-avatar
                v-if="data.item.stored"
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
              >
                <b-img :src="require(`@/assets/images/spore-icons/battery.svg`)" height="20"/>
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ decimal(data.item.stored, 2) }} Kw/h</span>
            </div>
          </template>
          <template #cell(status)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)">
              <b-avatar
                v-if="data.item.status"
                size="32"
                class="avatar-border-2 box-shadow-1"
                :variant="parseInt(data.item.status) === 1 ? 'success' : 'secondary'"
              >
                <feather-icon class="align-text-top" v-if="parseInt(data.item.status) === 1" icon="ZapIcon" size="18" />
                <feather-icon class="align-text-top" v-if="parseInt(data.item.status) === 0" icon="ZapOffIcon" size="18"/>
              </b-avatar>
              <b>
                <span class="align-text-top text-capitalize ml-1" :class="parseInt(data.item.status) === 1 ? 'text-success' : ''">{{parseInt(data.item.status) === 1 ? 'con servicio' : 'sin servicio' }}</span>
              </b>
            </div>
          </template>
          <template #cell(transmited)="data">
            <div class="text-nowrap selectable" @click="detail(data.item.uuid)" v-if="data.item.transmited">
              <b-avatar
                v-if="data.item.transmited"
                size="32"
                class="avatar-border-2 box-shadow-1"
                variant="transparent"
              >
                <b-img :src="require(`@/assets/images/spore-icons/fecha.svg`)" height="20"/>
              </b-avatar>
              <span class="align-text-top text-capitalize ml-1">{{ format(data.item.transmited) }}</span>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination v-model="currentPage" :total-rows="this.totalRows" :per-page="this.filters.limit" first-class last-class class="mb-0 mt-1 mt-sm-0">
                <template #first-text>
                  <feather-icon icon="ChevronsLeftIcon" size="18"/>
                </template>
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
                <template #last-text>
                  <feather-icon icon="ChevronsRightIcon" size="18"/>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BTable, BPagination, VBTooltip, BFormGroup, BFormInput, BAvatar, BImg, BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import request from '@/libs/request/index'
import { formatDateLanguages, decimales, empty } from '@/libs/tools/helpers'

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    vSelect,
    BCardHeader,
    BFormGroup,
    BAvatar,
    BImg,
    BOverlay,
    BFormInput
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      userRole: this.$store.state.session.AppActiveUser().userRole.toString(),
      show: false,
      loading: false,
      tableColumns: [
        { key: 'number', label: 'Número', sortable: true },
        { key: 'electricCurrent', label: 'Corriente', sortable: true },
        { key: 'frecuency', label: 'Frecuencia', sortable: true },
        { key: 'stored', label: 'Acumulado', sortable: true },
        { key: 'status', label: 'Estatus', sortable: true },
        { key: 'transmited', label: 'Última transmisión', sortable: true }
      ],
      totalRows: 0,
      currentPage: 1,
      count: 0,
      perPageOptions: [10, 25, 50, 100],
      response: [],
      filters: {
        limit: 20,
        offset: 0,
        number: '',
        byColumn: 'transmitedAt',
        byOrder: 'asc'
      }
    }
  },
  methods: {
    decimal (val, n) {
      return decimales(val, n)
    },
    async getResponse () {
      this.response = []
      const params = {
        url: '/meters',
        method: 'GET',
        params: this.filters
      }
      await request(params).then(data => {
        for (let index = 0; index < data.data.data.rows.length; index++) {
          this.response.push({
            uuid: data.data.data.rows[index].uuid,
            number: data.data.data.rows[index].number,
            deviceSerie: !empty(data.data.data.rows[index].cfeCabinet.cfeDevice) ? data.data.data.rows[index].cfeCabinet.cfeDevice.serie : '',
            electricCurrent: data.data.data.rows[index].electricCurrent,
            frecuency: data.data.data.rows[index].frecuency,
            stored: data.data.data.rows[index].storedEnergy,
            status: data.data.data.rows[index].status,
            transmited: data.data.data.rows[index].transmitedAt
          })
        }
        this.totalRows = data.data.data.count
        this.count = data.data.data.rows.length
      })
    },
    showFilter () {
      this.show = !this.show
    },
    format (date) {
      return formatDateLanguages(date, 'hour')
    },
    detail (uuid) {
      this.$router.push(`/meters/${uuid}/detail`)
    },
    add () {
      this.$router.push('/sinamed')
    },
    search () {
      this.filters.offset = 0
      this.currentPage = 1
      this.getResponse()
    },
    clear () {
      this.filters.offset = 0
      this.currentPage = 1
      this.filters.number = ''
      this.getResponse()
    }
  },
  created () {
    this.getResponse()
  },
  watch: {
    currentPage (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getResponse()
    },
    byColumn (val) {
      this.filters.byColumn = val
      this.currentx = 1
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
